<template>
  <section class="low-cost-house">
    <section class="hero">
      <div class="hero-container">
        <div class="hero-container-title">
          <h1>Faire construire sa maison pas cher ? C’est possible !</h1>
        </div>
        <div class="hero-container-img">
          <img
            src="@/assets/img/pages/low-cost-house-1.jpg"
            alt="Faire construire sa maison pas cher ? C’est possible !"
          />
        </div>
      </div>
    </section>
    <article class="article">
      <section class="fit-content card">
        <div class="smartpage-card smartpage-card-reverse">
          <div class="smartpage-card-content">
            <div>
              <h2>Qu’est ce qu’une maison pas chère ?</h2>
              <p>
                Faire construire sa maison individuelle est une aventure aussi passionnante que
                délicate sur le plan financier. Si vous avez opté pour un constructeur, outre sa
                capacité à vous proposer les plans de financement les plus adaptés à votre projet,
                il s’agit quand même d’un investissement conséquent qu’il vous faut maitriser. En
                tout cas, vous avez décidé de choisir un professionnel qui connaît son métier et qui
                offre un contrat de construction protecteur avec des garanties encadrées par la
                réglementation française.
              </p>
              <p>
                Une maison pas chère est une maison qui, sans sacrifier la qualité, offre le
                meilleur tarif du marché local.
              </p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img
              src="@/assets/img/pages/low-cost-house-2.jpg"
              alt="Qu’est ce qu’une maison pas chère ?"
            />
          </div>
        </div>
      </section>
      <section>
        <div class="smartpage-costing">
          <div class="smartpage-costing-content">
            <div>
              <h2>Configurez et chiffrez votre projet en moins de 2 minutes !</h2>
              <router-link class="costing-btn" :to="{ name: 'home', hash: '#hero' }">Commencer mon projet</router-link>
            </div>
            <div>
              <div>
                <img src="@/assets/img/chrono.svg" alt="chrono icon" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="fit-content card">
        <div class="smartpage-card">
          <div class="smartpage-card-content">
            <div>
              <h2>Un parti pris pour le petit prix et pour la qualité</h2>
              <p>
                Le prix de votre future maison dépend de nombreux facteurs : le mode constructif, le
                choix des matériaux, le nombre et le type d’équipements, les finitions, le niveau de
                personnalisation des plans… La plupart des constructeurs entrée de gamme nivèlent
                par le bas ces choix et limitent les possibilités pour proposer des maisons pas
                chères mais peu qualitatives… Faites également attention aux frais annexes souvent
                dissimulés au 1er chiffrage, ce qui diminue artificiellement le prix : frais de
                notaire sur l’achat du terrain, frais de raccordements, assurance dommage-ouvrage,
                taxes locales diverses…
              </p>
              <p>
                Le prix de la construction constitue un critère essentiel dans votre projet . Il est
                lié au choix du constructeur, alors n’hésitez pas à faire jouer la concurrence sur
                des modèles et des descriptifs comparables pour obtenir les meilleurs tarifs.
              </p>
              <p>
                Votre constructeur Maisons Alysia vous le recommande et nous sommes confiant sur
                notre positionnement et notre transparence pour votre plus grande satisfaction !
              </p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img
              src="@/assets/img/pages/low-cost-house-3.jpg"
              alt="Un parti pris pour le petit prix et pour la qualité"
            />
          </div>
        </div>
      </section>
    </article>
  </section>
</template>

<script>
export default {
  name: 'low-cost-house',
};
</script>

<style lang="sass">
.low-cost-house
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    @include smartpage-hero
  .article
    margin-bottom: 5rem
    .card + .card
      margin-top: 20rem
    section
      margin-top: 8rem
      &:first-child
        margin-top: 30rem
      .smartpage-card
        @include smartpage-card
      .smartpage-card-reverse
        @include smartpage-card-reverse
      .smartpage-costing
        @include smartpage-costing
      .smartpage-card-img
        margin-top: -30%
  .fit-content
    width: 90%
    max-width: 1180px
    margin: 0 auto
  @media (max-width: 768px)
    .article
      margin-bottom: 5rem
      section
        margin-top: 1.5rem
        &:first-child
          margin-top: 10rem
        .smartpage-card-img
          margin-top: 1.5rem
        .smartpage-card-reverse
          .smartpage-card-img
            margin-bottom: 0
      .card + .card
        margin-top: 1.5rem
</style>
